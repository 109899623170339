/**
 * @file incidents.js
 * @description State selector
 * @author Dirk Grobler
 * @copyright Loncel Technologies ltd 2020. All rights reserved
 */
import _ from 'underscore';
import { createSelector } from 'reselect';

const getServicesState = (state) => state.services;

export const getServices = createSelector(
  [getServicesState],
    (services) => (_.sortBy(services.items, item => item.name))
);
