/**
 * @file Layout.jsx
 * @description Application Main Layout
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import { connect } from 'react-redux';
import { ActiveIncidents, IncidentHistory } from './Incidents/Incidents';
import { getActiveIncidents } from '../redux/selectors/incidents';
import Services from './Services/Services';

const Layout = ({ activeIncidents }) => {
    const offset = activeIncidents ? 0 : 0;
    const span = activeIncidents ? 4 : 4;
    return (<main className="main-container">
        <Container fluid={true}>
            <Row>
                {activeIncidents ? <Col xs={12} md={8} lg={8}><ActiveIncidents /></Col> : null}
                <Col xs={12} md={{ span: span, offset: offset }} lg={{ span: span, offset: offset }}><Services /></Col>
            </Row>
            <Row>
                <Col><IncidentHistory /></Col>
            </Row>
        </Container>
    </main>);
}

const mapActiveStateToProps = (state) => ({
    activeIncidents: getActiveIncidents(state).length > 0,    
});

export default connect(mapActiveStateToProps)(Layout);