const APP_COLORS = {
    'gray-darker': '#263238',
    'gray-dark': '#455A64',
    'gray': '#607D8B',
    'gray-light': '#90A4AE',
    'gray-lighter': '#ECEFF1',
    'text-color': '#6C7177',
    'primary': '#448AFF',
    'success': '#4CAF50',
    'info': '#03A9F4',
    'warning': '#FFB300',
    'danger': '#F44336',
    'brand': '#9c29b7',
    'soil': '#a55d1a',
    'black': '#000000'
};

export default APP_COLORS;