/**
 * @file Services.jsx
 * @description Renders the status of services
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */
import React from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { connect } from 'react-redux';
import _ from 'underscore';
import { getServices } from '../../redux/selectors/services';
import { FaCircle, FaInfoCircle } from 'react-icons/fa/';
import ColorConstants from '../Styles/ColorConstants';
import '../Styles/Utils.scss';
import './Services.scss';

const serviceInfo = {
    'API': "Loncel's Public API for processing of historical data",
    'Telemetry': "Loncel's monitoring units and radio transceiver.",
    'Website': "Access to 'cloud.loncel.com' and Loncel's legacy website.",
    'Notifications': 'Notifications or alarms send as email, text or voice messages.'
}

const Header = () => {
    return (
        <div className='mt-xl mb service-header'>
            <div className="text-bold">Services</div>
        </div>);
}

const Service = ({ service }) => {
    const color = service.status === 100 ? ColorConstants.success : service.status > 0 ? ColorConstants.warning : ColorConstants.danger;
    const info = serviceInfo[service.name];
    return (
        <Row className="service m0">
            <Col xs={2} className="p0"><div className="ml-sm"><FaCircle size="16" color={color} /></div></Col>
            <Col xs={10} className="p0">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id={service.id}>
                            {info}
                        </Tooltip>}>
                    <span>
                        <span className="">{service.name}</span>
                        <span className="service-info">
                            <FaInfoCircle size="12" />
                        </span>
                    </span>
                </OverlayTrigger>
            </Col>
        </Row>
    );
}

const Services = ({ services }) => {
    if (_.isEmpty(services)) return <span />
    return (
        <div className="">
            <Header />
            <div className="">
                {services.map((service) => {
                    return (<Service key={service.id} service={service} />);
                })}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    services: getServices(state)
});

const ServicesState = connect(mapStateToProps)(Services);
export default ServicesState;