/**
 * @file incidents.js
 * @description State selector
 * @author Dirk Grobler
 * @copyright Loncel Technologies ltd 2020. All rights reserved
 */
import _ from 'underscore';
import { createSelector } from 'reselect';

const getIncidentsState = (state) => state.incidents;

export const getActiveIncidents = createSelector(
  [getIncidentsState],
    (incidents) => (_.filter(incidents.items, (item) => _.isNull(item.resolution)))
);

export const getIncidentsHistory = createSelector(
    [getIncidentsState],
      (incidents) => (_.filter(incidents.items, (item) => !_.isNull(item.resolution)))
  );