/**
 * @file loncelClient.jsx
 * @description Access to the loncel api
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */
import axios from 'axios';
import env from '../config/env';

export async function get(path, options = {}) {
    const url = "https://" + env.API.HOST + '/' + env.API.STAGE + '/' + path;
    return axios.get(url, options);
}