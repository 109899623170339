/**
 * @file env.js
 * @description Environment for the app
 * @author Dirk Grobler, Dushan Ranasinghage
 * @copyright Copyright 2024 - Lonceltech Ltd. All Rights Reserved.
 */
const { REACT_APP_STAGE } = process.env;

const dev = {
  API: {
    HOST: 'ambfx6vpb5.execute-api.us-west-2.amazonaws.com',
    STAGE: 'dev',
    REGION: 'us-west-2',
  },
  FROST: 'http://localhost:3001',
};

const frostDev = {
  API: {
    HOST: 'd0i0uj3ayk.execute-api.us-west-2.amazonaws.com',
    STAGE: 'query',
    REGION: 'us-west-2',
  },
  FROST: 'https://dev.frostsmart.com',
};

const frostStaging = {
  API: {
    HOST: 'hgcrrma7m7.execute-api.us-west-2.amazonaws.com',
    STAGE: 'query',
    REGION: 'us-west-2',
  },
  FROST: 'https://stage.frostsmart.com',
};

const frostProd = {
  API: {
    HOST: 'kggqlv6t14.execute-api.ap-southeast-2.amazonaws.com',
    STAGE: 'query',
    REGION: 'ap-southeast-2',
  },
  FROST: 'https://my.frostsmart.com',
};

console.log(`Stage ${process.env.REACT_APP_STAGE}`);

let env = dev;
switch (REACT_APP_STAGE) {
  case 'frost-dev':
    env = frostDev;
    break;
  case 'frost-staging':
    env = frostStaging;
    break;
  case 'frost-prod':
    env = frostProd;
    break;
  default:
    env = dev;
}

export default env;
