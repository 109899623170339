/**
 * @file index.js
 * @description Main reducer for the app
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */

import {
    combineReducers
} from 'redux';
import incidentReducer from './incidentReducer';
import serviceReducer from './serviceReducer';

const rootReducer = combineReducers({
    incidents: incidentReducer,
    services: serviceReducer
});
export default rootReducer;