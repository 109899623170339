/**
 * @file Refresher.jsx
 * @description Application Data Collector
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */

import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchIncidents } from '../redux/actions/incidents';
import { fetchServices } from '../redux/actions/services';

const Refresher = () => {
    const dispatch = useDispatch();
    dispatch(fetchIncidents());
    dispatch(fetchServices());

    setInterval(() => {
        dispatch(fetchIncidents());
        dispatch(fetchServices());
    }, 60000);

    return (<span />);
}

export default Refresher;
