/**
 * @file incidents.js
 * @description Actions for incidents
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */

import {
    get
} from '../../backend/loncelClient';

const fetchIncidentsStarted = () => ({
    type: "FETCH_INCIDENTS_START"
});
const fetchIncidentsSuccess = incidents => ({
    type: "FETCH_INCIDENTS_SUCCESS",
    payload: incidents
});
const fetchIncidentsError = errorMessage => ({
    type: "FETCH_INCIDENTS_ERROR",
    payload: errorMessage
});

export const fetchIncidents = () => async (dispatch) => {
    dispatch(fetchIncidentsStarted())
    try {
        const response = await get("status/incidents?history=true");
        const {
            incidents
        } = response.data;
        dispatch(fetchIncidentsSuccess(incidents))
    } catch (error) {
        dispatch(fetchIncidentsError(error.message))
    }
}