/**
 * @file CurrentIncidents.jsx
 * @description Renders the active incidents
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */

import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'underscore';
import { getActiveIncidents, getIncidentsHistory } from '../../redux/selectors/incidents';
import { TiWarningOutline } from 'react-icons/ti/';
import ColorConstants from '../Styles/ColorConstants';
import Description from './Description';
import '../Styles/Utils.scss';
import '../Styles/Cards.scss';
import './Incidents.scss';

const Header = ({history}) => {
    return (
        <div className='mt-xl mb incidents-header'>
            <div className="text-bold">{history ? 'History' : 'In progress'}</div>
        </div>);
}

const ActiveIncident = ({ incident }) => {
    const created = moment(incident.created);
    return (
        <div className="incident pt">
            <Row className="header" md={12}>
                <Col xs={2} md={1}><TiWarningOutline size="32" color={ColorConstants.danger} /></Col>
                <Col xs={10} md={11}><span className="text-bold title">{incident.summary}</span></Col>
            </Row>
            <Row md={6}>
                <Col xs={{ span: 2, offset: 2 }} md={{ span: 2, offset: 1 }}>                    
                    <div className="alert alert-danger">
                        In Progress
                    </div>                     
                </Col>
            </Row>
            <Row md={8}>
                <Col xs={{ offset: 2 }} md={{ offset: 1 }}>                    
                    <Description data={incident.description} />
                </Col>
            </Row>
            <Row md={8}>
                <Col xs={{ span: 10, offset: 2 }} md={{ span: 11, offset: 1 }}><div className='mt-sm'><span className ="text-bold">Affected services:</span><span className = "pl">{incident.components.toString()}</span></div></Col>
            </Row>
            <Row className='pb' md={8}>
                <Col xs={{ span: 10, offset: 2 }} md={{ span: 11, offset: 1 }}><div className=''><span className ="text-muted text-sm">Created: {created.calendar({sameElse: 'DD/MM/YYYY'})}</span></div></Col>
            </Row>
        </div>);
}

const Incident = ({ incident }) => {
    const created = moment(incident.created);
    return (
        <div className="incident pt">
            <Row className="header">
                <Col xs={2} md={1}><TiWarningOutline size="32" color={ColorConstants.danger} /></Col>
                <Col xs={10} md={11}><span className="text-bold title">{incident.summary}</span></Col>
            </Row>
            <Row>
                <Col xs={{ span: 2, offset: 2 }} md={{ span: 2, offset: 1 }}>
                    <div className="alert alert-success">
                        Resolved
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 10, offset: 2 }} md={{ span: 11, offset: 1 }}><div className='mt-sm'><span className ="text-bold">Affected services:</span><span className = "pl">{incident.components.toString()}</span></div></Col>
            </Row>
            <Row className='pb'>
                <Col xs={{ span: 10, offset: 2 }} md={{ span: 11, offset: 1 }}><div className=''><span className ="text-muted text-sm">Created: {created.calendar({sameElse: 'DD/MM/YYYY'})}</span></div></Col>
            </Row>
        </div>);
}

const Incidents = ({ incidents, history = false }) => {
    if (_.isEmpty(incidents)) return <span/>
    return (
        <div className="">
            <Header history={history}/>
            <div className="">
                { history ? incidents.map((incident) => {
                    return (<Incident key={incident.id} incident={incident}/>);
                }) : incidents.map((incident) => {
                    return (<ActiveIncident key={incident.id} incident={incident}/>);
                })
                }
            </div>
        </div>
    );
}

const mapActiveStateToProps = (state) => ({
    incidents: getActiveIncidents(state),
    history: false
});

const mapHistoryStateToProps = (state) => ({
    incidents: getIncidentsHistory(state),
    history: true
});

const IncidentInProgress = connect(mapActiveStateToProps)(Incidents);
const HistoricIncidents = connect(mapHistoryStateToProps)(Incidents);

export {HistoricIncidents as IncidentHistory}
export {IncidentInProgress as ActiveIncidents}