/**
 * @file Description.jsx
 * @description Renders the incident description
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */

import React from 'react';

const Paragraph = ({content}) => {
    return (
        <p className=''>
            {content.map(item => {
                if (item.type === 'paragraph') return <Paragraph content= {item.content} />
                else if (item.type === 'text') return <div>{item.text}</div>
                else return null;                
            })}
        </p>);
}

const Description = ({ data }) => {
    if (!data || data.type !== 'doc') return <span/>
    
    return (
        <div className="pt-sm">   
            {data.content.map(item => {
                if (item.type === 'paragraph') return <Paragraph content= {item.content} />
                else if (item.type === 'text') return <div>{item.text}</div>
                else return null;                
            })}         
        </div>);
}

export default Description;