/**
 * @file Header.jsx
 * @description Application Header implementation
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Refresher from './components/Refresher';
import Layout from './components/Layout';
import './App.scss';
import configureStore from './redux/store';

const store = configureStore();

const Home = () => {
    return (
        <div className="App">
            <Refresher />
            <Header />
            <Layout />
        </div>
    );
}

const App = () => {

    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path='*' element={<Navigate replace to="/" />} />
                </Routes>
            </Router>

        </Provider>);
}

export default App;