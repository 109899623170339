/**
 * @file services.js
 * @description Actions for services
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */

import {
    get
} from '../../backend/loncelClient';

const fetchServicesStarted = () => ({
    type: "FETCH_SERVICES_START"
});
const fetchServicesSuccess = services => ({
    type: "FETCH_SERVICES_SUCCESS",
    payload: services
});
const fetchServicesError = errorMessage => ({
    type: "FETCH_SERVICES_ERROR",
    payload: errorMessage
});

export const fetchServices = () => async (dispatch) => {
    dispatch(fetchServicesStarted())
    try {
        const response = await get("status/services");
        const {
            services
        } = response.data;
        dispatch(fetchServicesSuccess(services))
    } catch (error) {
        dispatch(fetchServicesError(error.message))
    }
}