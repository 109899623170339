/**
 * @file Header.jsx
 * @description Application Header implementation
 * @author Dirk Grobler, Dushan Ranasinghage
 * @copyright Copyright 2024 - Lonceltech Ltd. All Rights Reserved.
 */

import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import env from '../config/env';

import { getLogo, getAppName } from '@frostsmart/loncel-brand-kit';

const redirectToSubscribe = () => {
    const url = env.FROST + '/incident/subscription';
    window.open(url, "_self");
}

const Header = () => {
    return (
        <header className="App-header">
            <Container>
                <Row>
                    <Col>
                        <span className="header-logo">
                            <img src={getLogo("status", "flatLogo")} alt="" />
                            <span className="pl header-title">{getAppName("status")}</span>
                        </span>
                    </Col>
                    <Col className="d-none d-sm-inline-block"><span style={{ float: 'right', height: '60px', display: 'flex', alignItems: 'center' }}><Button variant="light" onClick={redirectToSubscribe} size="sm">Subscribe</Button></span></Col>
                </Row>
                <Row className="d-sm-none">
                    <Col><span style={{ float: 'right' }}><Button onClick={redirectToSubscribe} variant="link" size="sm">Subscribe</Button></span></Col>
                </Row>
            </Container>
        </header>);
}

export default Header;
