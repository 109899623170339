/**
 * @file store.js
 * @description Keeps the state of the application
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */
import {
    createStore,
    applyMiddleware
} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
}
const configureStore = (preLoadedState) => createStore(rootReducer, preLoadedState, applyMiddleware(...middlewares));
export default configureStore;