/**
 * @file incidentReducer.js
 * @description Reducer for incidents
 * @author Dirk Grobler
 * @copyright Copyright 2020 - Lonceltech Ltd. All Rights Reserved.
 */

const INITIAL_STATE = {
    items: [],
    isFetching: false,
    errorMessage: undefined
};
const incidentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "FETCH_INCIDENTS_START":
            return {
                ...state,
                isFetching: true
            };
        case "FETCH_INCIDENTS_SUCCESS":
            return {
                ...state,
                isFetching: false,
                items: action.payload
            };
        case "FETCH_INCIDENTS_ERROR":
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload
            };
        default:
            return state;
    }
};
export default incidentReducer;